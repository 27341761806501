import './HeaderLayout.css';

function HeaderLayout() {
  return (
    <header>
      <div>
        <h1>8zehn Saiten</h1>
      </div>
    </header>
  );
}

export default HeaderLayout;

