import Layout from '../../layout/Layout';
import './KontaktPage.css';

function KontaktPage () {

    return (
        <Layout>
            <h1 className='titles-page'>KONTAKT</h1>
            
            <div className='email-container'>
            <h2 className='titles'>E-Mail</h2>
            <p><a href="mailto: info@8zehnsaiten.de">info@8zehnsaiten.de</a></p>
            </div>

            <div>
            <h2 className='titles'>Telefon</h2>
            <p><a href="tel:+491794623367">+49 179 4623367</a></p>
            </div>
            
        </Layout>
    )
}

export default KontaktPage