
import './App.css';
import { Routes, Route } from 'react-router-dom';
import HomePage from './webApp/components/homePage/homePage';
import KontaktPage from './webApp/components/kontaktPage/KontaktPage';
import ImpressumPage from './webApp/components/impressumPage/ImpressumPage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/home" element={<HomePage/>} />
        <Route path="/kontakt" element={<KontaktPage/>} />
        <Route path="/impressum" element={<ImpressumPage/>} />
      </Routes>
      
    </div>
  );
}

export default App;
