import "./homePage.css";
import Layout from "../../layout/Layout.jsx";
import imageMartin from "../../../img/martin.jpg";
import imageThomas from "../../../img/thomas.jpg";

function HomePage() {
  return (
    <Layout>
      <div className="band-members">
        <div className="band-member">
          <img src={imageThomas} alt="Thomas Schmotz (Foto: C. Schmotz)" />
          <h2 className="member-names-h2">Thomas S. (Foto: C. Schmotz)</h2>
        </div>
        <div className="band-member">
          <img src={imageMartin} alt="Martin Schmidt (Foto: M. Beiersdorf)" />
          <h2 className="member-names-h2">Martin S. (Foto: M. Beiersdorf)</h2>
        </div>
      </div>
      <p className="intro-text">Born as a Corona-"but we need to keep on playing music"-project, we play our acoustic favourites from 20+ years of band & stage experience.
From B to S as in Beatles, Cash, Cohen, Dylan, Eagles, Simon & Garfunkel, Springsteen</p>
    </Layout>
  );
}

export default HomePage;
