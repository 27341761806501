import Layout from "../../layout/Layout";
import "./ImpressumPage.css";

function ImpressumPage() {

  return (
    <Layout>
      <h1 className="titles-page">IMPRESSUM</h1>
      <p className="impressum-text">
      Martin Schmidt <br /> 
      Görschstraße 26A <br /> 
      13187 Berlin <br /> 
      E-Mail: <a href="mailto:info@8zehnsaiten.de">info@8zehnsaiten.de</a> <br /> 
      Tel.: <a href="tel:+491794623367">+49 179 4623367</a>
      </p>
    </Layout>
  );
}

export default ImpressumPage;
