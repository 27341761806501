import HeaderLayout from "./headerLayout/HeaderLayout.jsx"
import './Layout.css';
import NavigationLayout from "./navigationLayout/NavigationLayout.jsx"



function Layout (props) {

    return (

    <div className="layout-container">
        <HeaderLayout></HeaderLayout>
        <NavigationLayout></NavigationLayout>
        <main>
          {props.children}
        </main>
    </div>
    )
}

export default Layout